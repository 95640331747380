.topnavmenu {
  width: 100%;
  text-decoration: none;
  background: #ffffff;
  opacity: 1;
  overflow: hidden;
  text-align: center;

  border-bottom: 2px solid #24181a;
  position: relative;
  z-index: 1000;
  padding-left: 10px;
}

.topnavmenu .nav-btns {
  color: #24181a;
  border-radius: 4px;
  font-weight: 600;
  padding: 7px 12px !important;
  text-decoration: none;
  margin-left: 10px;
}

.nav-btns:hover {
  background-color: #bfe2f5;
  color: #241a1a;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #3085de;
}

.active {
  background-color: #bfe2f5;
  color: #241a1a !important;
  border-radius: 4px;
  box-shadow: 0 0 0 2px #3085de;
}

.header_navbar {
  background-color: #131921 !important;
}

/*===== NAVBAR =====*/
.header_navbar {
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  z-index: 999;
  border-bottom: 1px solid rgba(124, 134, 154, 0.3);
  background-color: #fff;
}

.navbar {
  padding: 0;
  position: relative;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky .navbar {
    padding: 10px 0;
  }
}

@media (max-width: 767px) {
  .sticky .navbar {
    padding: 10px 0;
  }
}

.fw-600 {
  font-weight: 600;
}

.dashboard-area .active {
  box-shadow: none;
}

.overflow {
  overflow-x: hidden;
}

.bordreside {
  border-radius: 0px 15px 15px 0px;
}

card {
  border: 2px solid gray;
}

.sticky {
  position: sticky !important;
  top: 0;
}

.border-none {
  border: none;
}

.shadow-box {
  border-radius: 6px !important;
  background: #f7f7fc;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.card {
  padding: 15px 15px;
  background: #fff;
  box-shadow: 0 13px 19px rgba(0, 0, 0, 0.07);
}

.card:hover {
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.3) !important;
}

.card {
  transition: 200ms all;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
}

.btn-main-yellow {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 24px;
  font-size: 16px;
  border-radius: 4px;
  color: #131921;
  cursor: pointer;
  z-index: 5;
  background: #ffc107;
  width: 200px;
}

/*===== All Button Style =====*/
.btn-main {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 16px;
  border-radius: 5px 5px;
  color: white;
  cursor: pointer;
  background: #3085de;
  transition: all 0.4s ease-out 0s;
}

.btn-main:hover {
  color: white !important;
  border: 1px solid #131921;
}

.btn-hover {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.btn-hover::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.btn-hover:hover::after {
  width: 100%;
}

.main-dark-btn-hover:hover {
  color: #fff;
  border: 1px solid #3085de;
}

.main-dark-btn-hover::after {
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.main-dark-btn-hover:hover::after {
  width: 100%;
}

.list_div {
  padding: 8px 0px;
  margin: 0px 8px;
  border-bottom: 1px solid #dedede;
}

.p-35 {
  padding: 35px;
}

.sidebar {
  z-index: 1 !important;
  padding-left: 35px !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400;
}

p {
  font-family: "Outfit", sans-serif !important;
  font-weight: 300 !important;
}

h1 {
  font-size: 36px;
  color: #131921;
  font-weight: 500;
}

h2 {
  color: #131921;
  font-size: 32px;
  font-weight: 500;
}

h5 {
  font-size: 20px !important;
}

p {
  font-size: 16px;
  color: #646163;
  font-weight: 400;
}

.feture {
  border: 1px solid #ddd;
  padding: 3px 12px;
  border-radius: 4px;
}

.share {
  border: 0px;
  width: auto;
  color: #646163;
}

.featured-ribbon {
  color: #fff;
  border-radius: 4px;
  margin-right: 34px;
  border: 16px solid #3085de;
  border-right: 14px solid transparent;
  height: 0;
  line-height: 0;
  width: 124px;
}

.p-25 {
  padding: 25px;
}

.text-orange {
  color: #3085de !important;
}

/* recrut ment.css start heare */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400 !important;
}

th {
  font-weight: 500;
  color: #646163;
}

td {
  color: #646163;
  font-weight: 300;
}

p,
li {
  font-family: "Outfit", sans-serif !important;
  font-weight: 300 !important;
}

h2 {
  color: #131921;
  font-size: 32px;
  font-weight: 500;
}

h3 {
  color: #131921;
}

.navbar .nav-item .dropdown-menu {
  border-radius: 0px 0px 8px 8px !important;
  border: 0px !important;
  background: #212e36 !important;
  top: 100% !important;
  left: 0 !important;
  z-index: 1001 !important;
  margin-top: var(--bs-dropdown-spacer) !important;
}

.navbar .nav-item .dropdown-menu li {
  padding: 8px 16px;
  border-bottom: 0px none;
}

.navbar .nav-item .dropdown-menu li a {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  display: flex;
}

.navbar .nav-item:hover {
  background: #212e36;
  color: #131921;
  margin-bottom: -27px;
  border-bottom: 1px solid #3085de;
}

.navbar .nav-item:hover .dropdown-menu {
  display: block;
}

.navbar .nav-item .dropdown-menu {
  display: none;
}

.text-black {
  color: #131921;
}

.text-blue {
  color: #0058a2 !important;
  font-weight: 500;
}

.bg-grey {
  background-color: #f2f2f2;
}

.background-dark {
  background-color: #131921;
}

.recruitment-card .card {
  padding: 20px 20px;
  background-color: #ffffff;
  transition: 200ms all;
  border: 1px solid #ddd;
}

.recruitment-box-style {
  padding: 15px 11px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid rgba(124, 134, 154, 0.25);
  box-shadow: 0px 3px 35px rgba(218, 222, 228, 0.3);
}

.fs-46 {
  font-size: 46px;
}

.border-orange {
  border-width: 2px !important;
  border-color: #3085de !important;
}

.botd {
  font-size: 16px !important;
  height: 18px;
  margin-top: 4px;
  border-right: 1px solid #3085de;
}

.btn-white {
  width: 250px;
  height: 50px;
  background-color: #fff;
  color: #131921;
  border-radius: 4px;
  border: 2px solid #131921;
  font-size: 18px;
}

.link {
  text-decoration: none;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.p-10 {
  padding: 10px;
}

.font-20 {
  font-size: 20px;
}

.font-18 {
  font-size: 18px;
}

.p-30 {
  padding: 30px;
}

.bg-gray {
  background-color: #f8f8f8;
}

.bg-color {
  background-image: url(assets/image/Blue\ Photo\ Beauty\ Skincare\ Blog\ Banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card:hover {
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.3) !important;
}
.btn-yellow {
  color: #131921;
  background-color: #fcb614;
}

.btn-main-yellow {
  background: #ffc107;
}

.card {
  padding: 15px 15px;
  background: #fff;
  transition: 200ms all;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
}

i.fa.fa-dot-circle-o.fs-5.text-dark {
  background: radial-gradient(
    ellipse at center,
    #0058a2 0,
    #0058a2 50%,
    #fff 55%,
    #fff 100%
  );
  background-color: #0058a2;
  height: 20px;
  width: 20px;
  border: 2px solid #0058a2;
  border-radius: 100%;
  padding: 0px;
  color: transparent !important;
}

i.fa.fa-circle-o.me-2.text-grey,
i.fa.fa-circle-o.fs-5.text-dark {
  background-color: #fff;
  height: 20px;
  width: 20px;
  border: 2px solid #646163;
  border-radius: 100%;
  padding: 0px;
  color: transparent !important;
}

.brand img {
  width: 175px;
  height: 175px;
  object-fit: contain;
  box-shadow: 0 0 1px rgba(25, 27, 35, 0.16), 0 1px 2px rgba(25, 27, 35, 0.12);
  margin: 10px;
  padding: 20px !important;
  object-position: center;
  border-radius: 4px;
  background-color: #fff;
}

.slider h3 {
  background: #fff;
  color: #3498db;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  text-align: center;
}

.border-dark {
  border: 1px solid #131921;
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
  h2 {
    font-size: 26px !important;
  }

  h1 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #646163;
    line-height: 22px;
  }

  .table td {
    font-size: 14px;
  }

  .p-35 {
    padding: 20px;
  }

  .wishlist {
    display: block;
    height: 25px;
    width: 25px;
    margin-top: 0px;
  }

  .offcanvas-body.mobile a {
    border-bottom: 1px solid #ddd;
    color: #131921;
    padding: 18px 0px;
    font-size: 16px;
    width: 100%;
  }

  .footer-area {
    padding-top: 40px;
  }

  .footer-area .widget-wrapper .footer-widget {
    margin-bottom: 0px;
  }

  .footer-area .widget-wrapper .footer-widget h4 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas-body.mobile a {
    border-bottom: 1px solid #ddd;
    color: #131921;
    padding: 18px 0px;
    font-size: 18px;
    width: 100%;
  }

  #pagination li a {
    padding: 5px 9px !important;
    margin-right: 0px !important;
  }

  .dashboard-area {
    margin-top: -87px;
  }

  .sidebar {
    padding-left: 10px;
  }

  .franchise-area-padding {
    padding: 15px 15px 0px 15px;
  }

  .p-35 {
    padding: 20px;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
  }

  h2 {
    font-size: 26px;
    font-weight: 500;
  }

  .table td {
    font-size: 14px;
  }
}

.fs-14 {
  font-size: 14px;
}

.li-bg {
  min-width: 220px;
}

.li-bg:hover {
  background-color: #131921;
}

.li-bg:hover a {
  color: #3085de;
}

.set-dropdown {
  padding: 5px 15px;
  border-bottom: 1px solid #ddd;
}

li.set-dropdown:last-child {
  border-bottom: 0px none;
}

.li-bg {
  min-width: 220px;
}

.li-bg:hover {
  background-color: #131921;
}

.li-bg:hover a {
  color: #3085de;
}

.set-dropdown {
  padding: 5px 15px;
  border-bottom: 1px solid #ddd;
}

li.set-dropdown:last-child {
  border-bottom: 0px none;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #646163;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
  text-decoration: none !important;
}

a {
  transition: all 0.3s ease-out 0s;
  color: #24181a;
}

a:hover {
  transition: all 0.3s ease-out 0s;
  color: #24181a;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
  color: #24181a;
}

h5 {
  font-size: 18px;
  color: #24181a;
}

p,
label {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #646163;
  margin: 0px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 22px;
}

h6 {
  font-size: 16px;
}

@media (max-width: 767px) {
  h1 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  p,
  label {
    line-height: normal;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 26px;
  }

  h5 {
    font-size: 18px;
  }

  p,
  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #646163;
    margin: 0px;
  }

  .text-mobilemargine {
    font-size: 14px;
  }
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.footer-area {
  background: linear-gradient(
    0deg,
    rgba(3, 13, 13, 1) 8%,
    rgba(63, 107, 156, 1) 98%
  );
  padding-top: 50px;
  color: #9e9e9e;
}

.footer-area .widget-wrapper {
  position: relative;
  z-index: 1;
}

.footer-area .widget-wrapper .map-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.footer-area .widget-wrapper .about {
  padding-right: 56px;
}

.footer-area .widget-wrapper .footer-widget {
  margin-bottom: 40px;
}

.footer-area .widget-wrapper .footer-widget h4 {
  margin-top: 20px;
  font-size: 20px;
  color: #fff;
  margin-bottom: 35px;
}

.footer-area .widget-wrapper .footer-widget ul li {
  color: #fff;
  margin-bottom: 12px;
}

.footer-area .widget-wrapper .footer-widget ul li span {
  display: block;
}

.footer-area .widget-wrapper .footer-widget ul a {
  color: #9e9e9e;
}

.footer-area .widget-wrapper .footer-widget ul a:hover {
  color: #3085de;
}

.footer-area .widget-wrapper .footer-widget ul.social li {
  margin-right: 25px;
}

.footer-area .widget-wrapper .footer-widget ul.social li a {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  background: #fff;
  color: #131921;
}

.footer-area .widget-wrapper .footer-widget ul.social li a:hover {
  background: #3085de;
  color: #fff;
  box-shadow: drop-shadow(0px 5px 15px rgba(255, 107, 107, 0.3));
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

input,
textarea {
  width: 100%;
  border: 1px solid #646163;
  padding: 8px 20px;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;

  transition: all 0.3s ease-out 0s;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
}

input:focus,
textarea:focus,
.single-form input:focus,
.form-control:focus {
  border-color: #131921 !important;
  box-shadow: 0 0 0 0.25rem #0058a250 !important;
}

.btn-history {
  text-align: center;
  border: 2px solid #3085de;
  border-radius: 4px;
}

.btn-history:hover {
  box-shadow: 0px 0px 6px #3085de;
  border: 2px solid #3085de;
}

.back-to-top.btn-hover {
  position: fixed;
  z-index: 99999;
}

.back-to-top:hover {
  color: #fff;
}

.back-to-top {
  width: 45px;
  height: 45px;
  background: #3085de;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99999;
  cursor: pointer;
  transition: all 0.6s ease-out 3s;
}
.navbar-toggler {
  border: none !important;
}

/* modal  */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0;
}
.dashboard-area {
  background: #f9f9f9;
}

.dashboard-area .left-sidebar-wrapper {
  padding: 50px 0;
}

.dashboard-area .left-sidebar-wrapper .sidebar-header {
  padding: 0 30px;
  text-align: center;
}

.dashboard-area .left-sidebar-wrapper .sidebar-header .image {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
  overflow: hidden;
}

.dashboard-area .left-sidebar-wrapper .sidebar-header .image img {
  width: 100%;
}

.dashboard-area .left-sidebar-wrapper .sidebar-header .info {
  /* border-bottom: 1px solid rgba(124, 134, 154, 0.47); */
  margin-bottom: 20px;
}

.dashboard-area .left-sidebar-wrapper .sidebar-header .info h3 {
  margin-bottom: 10px;
}

.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a {
  display: block;
  border-top: 1px solid #ddd;
  padding: 17px 30px;
  color: #646163;
  position: relative;
}

.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  border-radius: 0 5px 5px 0;
  transition: all 0.3s ease-out 0s;
}

.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a.active::before,
.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a:hover::before {
  background: #fe9900;
}

.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a.active i,
.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a:hover i {
  color: #fe9900;
}

.dashboard-area .left-sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 15px;
  transition: all 0.3s ease-out 0s;
  font-size: 22px;
  color: #131921;
  vertical-align: middle;
}

.dashboard-area .dashboard-wrapper .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.dashboard-area .dashboard-wrapper .title .main-btn {
  font-size: 16px;
  font-weight: 400;
  padding: 7px 16px;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .ads-btn {
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid rgba(124, 134, 154, 0.25);
  border-radius: 5px;
  color: #131921;
  margin-right: 5px;
  margin-bottom: 5px;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .ads-btn:hover,
.dashboard-area .dashboard-wrapper .cards-wrapper .ads-btn.active {
  background: #fe9900;
  color: #fff;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card {
  padding: 25px 15px;
  display: flex;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dashboard-area .dashboard-wrapper .cards-wrapper .single-card {
    flex-direction: column;
    padding: 20px 10px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-area .dashboard-wrapper .cards-wrapper .single-card {
    flex-direction: column;
    padding: 20px 10px;
    text-align: center;
  }
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card:hover {
  background: #fe9900;
  border-color: #fe9900;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card:hover .icon i {
  background: #fff;
  color: #fe9900;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card:hover .text h5,
.dashboard-area .dashboard-wrapper .cards-wrapper .single-card:hover .text p {
  color: #fff;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card .icon {
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dashboard-area .dashboard-wrapper .cards-wrapper .single-card .icon {
    margin: 0px auto 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-area .dashboard-wrapper .cards-wrapper .single-card .icon {
    margin: 0px auto 8px;
  }
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card .icon i {
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background: #fe9900;
  transition: all 0.3s ease-out 0s;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card .text h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  transition: all 0.3s ease-out 0s;
}

.dashboard-area .dashboard-wrapper .cards-wrapper .single-card .text p {
  transition: all 0.3s ease-out 0s;
}

.dashboard-area .dashboard-wrapper .table-wrapper thead,
.dashboard-area .dashboard-wrapper .table-wrapper tbody,
.dashboard-area .dashboard-wrapper .table-wrapper td,
.dashboard-area .dashboard-wrapper .table-wrapper th {
  border: none;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  .table
  > :not(caption)
  > *
  > * {
  padding: 1rem 0.5rem;
  margin-bottom: 10px;
}

.dashboard-area .dashboard-wrapper .table-wrapper table thead {
  margin-bottom: 20px;
}

.dashboard-area .dashboard-wrapper .table-wrapper table thead tr th h5 {
  font-size: 18px;
  font-weight: 600;
  color: #131921;
}

.dashboard-area .dashboard-wrapper .table-wrapper table tbody tr {
  vertical-align: middle;
  border-bottom: 8px solid #fff;
  padding: 16px 20px;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 3px 35px rgba(218, 222, 228, 0.3);
}

.dashboard-area .dashboard-wrapper .table-wrapper table tbody tr:hover::before {
  background: #fe9900;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dashboard-area .dashboard-wrapper .table-wrapper table tbody tr td {
    min-width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-area .dashboard-wrapper .table-wrapper table tbody tr td {
    min-width: 150px;
  }
}

@media (max-width: 767px) {
  .dashboard-area .dashboard-wrapper .table-wrapper table tbody tr td {
    min-width: 150px;
  }
}

.dashboard-area .dashboard-wrapper .table-wrapper table tbody tr td .image {
  display: flex;
  align-items: center;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .image
  .form-check {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .image
  .form-check
  input {
  padding: 0;
  background-color: #fe9900;
  outline: none;
  border-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .image
  .form-check
  input:checked {
  background-color: #fe9900;
}

.dashboard-area .dashboard-wrapper .table-wrapper table tbody tr td h6 {
  font-size: 16px;
  font-weight: 600;
}

.dashboard-area .dashboard-wrapper .table-wrapper table tbody tr td span.ad-id {
  font-size: 14px;
  font-weight: 300;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  span.category {
  color: #7c869a;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .status-btn {
  width: 100px;
  text-align: center;
  font-size: 15px;
  padding: 4px 18px;
  font-weight: 400;
  border-radius: 30px;
  background: #fe9900;
  color: #fff;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .action-btns {
  display: flex;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .action-btns
  a {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #f5f7fc;
  color: #7c869a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 5px;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .action-btns
  a:hover {
  color: #fff;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .action-btns
  a.eye-btn:hover {
  background: lightseagreen;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .action-btns
  a.edit-btn:hover {
  background: #131921;
}

.dashboard-area
  .dashboard-wrapper
  .table-wrapper
  table
  tbody
  tr
  td
  .action-btns
  a.delete-btn:hover {
  background: #fe9900;
}

.box-style {
  margin-bottom: 15px;
  padding: 30px 30px;
  border-radius: 8px;
  background: #fff;

  box-shadow: 0px 3px 35px rgba(218, 222, 228, 0.3);
}

.ql-editor {
  padding: 0 !important;
  margin: 0;
}
